import { connect } from "react-redux";

import React from "react";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { fade } from "@material-ui/core/styles/colorManipulator";
import "./admin.scss";
import Home from "../pages/home/index";
import { Auth } from "aws-amplify";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HomeIcon from '../assets/icons/home.png';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Modal from './popup'
import Button from '@material-ui/core/Button';
import {API_URL} from '../utils/constants';
import SolverseToolBar from './toolbar';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import InnerRoutes from './innerRoutes'
import UserReports from '../pages/reports/UserReports'
import ProjectReports from '../pages/reports/ProjectReports'
import PaymentReports from '../pages/reports/PaymentReports'
const drawerWidth = 240;

// List item as an image
const listIcons = [
  HomeIcon
];

const useStyles = makeStyles(theme => ({
  mainDiv:{
    //border:"dotted",
    height : "100vh"
  },
  text:{
    fontSize: "14px"
  },
  root: {
    display: 'flex',
    backgroundColor:'#fff',
    height: '100vh',
    // overflow: 'hidden',

  },
  colorChange: {
    backgroundColor: '#ECF3F5'
  },
  icon: {
        margin: theme.spacing.unit * 2
      },
      grow: {
        flexGrow: 1
      },
      // menuButton: {
      //   marginLeft: 12,
      //   marginRight: 36
      // },
      drawerPaper: {
        width: drawerWidth
      },
      inputRoot: {
        color: "inherit",
        width: "100%"
      },
      inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
          width: 200
        }
      },
  appBar: {
    background: "#052851",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: theme.zIndex.drawer + 1,
    //border:"dotted",
    height: theme.spacing.unit * 10
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    marginLeft: 12
  },
  hide: {
    display: 'none',
  },
  projectName: {
    paddingLeft: '4%',
    whiteSpace: 'nowrap'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
   //padding: theme.spacing(3),
  },
  innercontent: {
    height:'100vh',
    backgroundColor:'#ecf3f5',
    padding:'20px'
  },
  listicon: {
    maxWidth:'100%',
    maxHeight:'100%'
  },
  imgDiv: {
    width:'19px',
    height:'16px'
  },
  profileName: {
    padding: '7%',
    minWidth:'7em',
    textAlign:'center'
  },
  profileArrow: {
    padding: '0%',
    color: 'white'
  },
  profileAvatar: {
    padding: '0%',
    fontSize: '2rem'
  },
  menuIcon: {
    padding: '0.5%'
  },
  solverseLogo: {
    width: '11em',
    paddingTop: '2.2%',
    paddingBottom: '2.2%',
    paddingLeft: '2%'
  },
  sideIcons:{
    color: 'rgba(0, 0, 0, 0.54)',
    /* display: inline-flex; */
    minWidth: '46px',
    flexShrink: '0',
    marginLeft:"10px"
    },
    sideText:{
      fontSize: '14px',
    }
}));
const styles = theme => ({
  listItemText:{
    fontSize:'0.7em',//Insert your required size
  }
});

export default function MiniDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl,setanchorEl] = React.useState(null)
  const [username,setusername] = React.useState('')
  const [userId,setuserId] = React.useState('')
  const [token,settoken] = React.useState('')
  const [modal, setModal] = React.useState(false)
  const [text, setText] = React.useState('')
  const [buttonText, setButtonText] = React.useState('')
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [popSelectedProject, setPopSelectedProject] = React.useState(false)
  const [userEmail, setUserEmail] = React.useState([])
  const [fullname, setFullname] =  React.useState('')
  const [displayName, setDisplayName] =  React.useState('')
  const [phwork, setphwork] =  React.useState('')
  const [phmobile, setphmobile] =  React.useState('')
  const [bio, setbio] =  React.useState('')
  const [preview, setPreview] = React.useState('');

 
  // window.onpopstate  = () => {
  //   //your code...
  //   //alert("back");
  //   const url=window.location.pathname
  //   console.log("URL######",url)
  //   setMenuValue(url.substring(1))
  //   }

//   window.onpopstate = function () {
//   // alert("dfdf");
//    const url=window.location.pathname
//     console.log("URL######",url)
//     setMenuValue(url.substring(1))
// };

 

  function handleClickOpen() {
    setAlertOpen(true);
  }

  function handleClose() {
    setAlertOpen(false);
  }
  function handleDrawerOpen() {
    // setOpen(!open);
  }
  const clickHandle = (e, value) => {
 
   
  };
 
  const profileClick =  event => {
    setanchorEl(event.currentTarget)
    props.history.push("viewProfile")
    // this.setState({anchorEl: event.currentTarget})
    
  }
  const profileClose = () => {
    setanchorEl(null)

    // this.setState({anchorEl: null})
  }
  const closeModal = () => {
    setPopSelectedProject(false)
  }
  const popupCancel = () => {
    // setButtonText('Projects')
    setPopSelectedProject(false)
  }

  const checkout = async() => {
    props.history.push('/');
  }
  const logout = async () => {

    setanchorEl(null)
    // this.setState({anchorEl: null})
    localStorage.clear()
    
    await Auth.signOut()
    //alert("You have successfully Logout")
    props.history.push('/')
  }


  const loadLoginScreen = ()=> {
    props.history.push('/')
  }
  


  React.useEffect(() => {
    // async function getToken() {
    //   const userInfo =  await Auth.currentAuthenticatedUser();
    //   const token = userInfo.signInUserSession.accessToken.jwtToken
    //   Auth.currentSession()
    //   .then(data => console.log(''))
    //   setusername(userInfo.attributes.name)
    //   setuserId(userInfo.username)
    //   settoken(token)
    //   localStorage.setItem(LOCALSTORAGEKEY_TOKEN, token);   
    // }

    // getToken()
    // .catch(error => {
    //   console.log('error => ' + error)
    //   //setProgressBar(false);
    //   console.log("session@@@@@@@@@@");
    //   checkout();
    // })   

    console.log('user.username')


    Auth.currentAuthenticatedUser()
    .then(user => {
      console.log('user.username')

    }).catch(err => {
      console.log("Error@@@@@@@@@@@@")
      props.history.push("/")
    })
    
  },[])



  const popup = (value) =>{
      // selectMenu('Projects')
      setModal(false)
    }
    const listItems = ["Home"]
    const routes = [
      {
        path: "/home",
        exact: true,
        component: Home,
        main: () => <Home/>
      },
      {
        path: "/userReports",
        exact: true,
        component: UserReports,
        main: () => <UserReports/>
      },
      {
        path: "/projectReports",
        exact: true,
        component: ProjectReports,
        main: () => <ProjectReports/>
      },
      {
        path: "/paymentReports",
        exact: true,
        component: PaymentReports,
        main: () => <PaymentReports/>
      }
      
    ];
    function RouteWithSubRoutes(route) {
      return (
        <Route
          path={route.path}
          render={props => (
            // pass the sub-routes down to keep nesting
            <route.component {...props} routes={route.routes} />
          )}
        />
      );
    }

  return (
    <div className={classes.mainDiv}>
    <div className={classes.root} >
      <CssBaseline />
      <AppBar
        position="fixed"
        className={classes.appBar}
      >
       <SolverseToolBar
            handleDrawerOpen={handleDrawerOpen}
            username={displayName?displayName:username}
            profileClick={profileClick}
            anchorEl={anchorEl}
            profileClose={profileClose}
            handleClickOpen={handleClickOpen}
            loadLoginScreen={loadLoginScreen}
            displayImage={preview}
          />
      </AppBar>
      {/* <Router> */}
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
             {[
              "Home"
            ].map((text, index) => (
              <ListItem
                key={text}
                button
                onClick={e => clickHandle(e, text)}
                className={props.location.pathname.substr(1) === listItems[index]
                  ? classes.colorChange
                  : ""}
              >
                <div className={classes.sideIcons}>
                  <div className={classes.imgDiv}>
                  <img src={listIcons[index]} className={classes.listicon}/>
                  </div>
                </div>
                <ListItemText classes={{primary:classes.sideText}}  primary={text} />
                
              </ListItem>
            ))}
          </List>
      </Drawer>
      
      {/* </Router> */}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <InnerRoutes routes={routes}/>
                {/* <div className={classes.innercontent}>
            {routes.map((route, index) => (
                // Render more <Route>s with the same paths as
                // above, but different components this time.
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.main}
                />
              ))}
          </div> */}
      </main>
        <Dialog
          open={alertOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Are you sure want to logout ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={logout} color="primary">
            Yes
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </div>
    );  
}

