import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    innercontent: {
        height:'100vh',
        backgroundColor:'#ecf3f5',
        padding:'20px'
      },
}));
export default function InnerRoutes(props) {
    const classes = useStyles();
    return (
        <div className={classes.innercontent}>
            {props.routes.map((route, index) => (
                // Render more <Route>s with the same paths as
                // above, but different components this time.
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.main}
                />
              ))}
          </div>
    )
}