import React from 'react';
import './forgetPassword.scss'
import {Link} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { Auth } from "aws-amplify";
import {
    withStyles,
    MuiThemeProvider,
    createMuiTheme
  } from "@material-ui/core/styles";
import backgroundImage from '../../../assets/images/backgroundimage.png'
import solverseLogo from '../../../assets/icons/solverseBlue.png'
import LinearProgress from '@material-ui/core/LinearProgress';
import Email from '../../../assets/icons/email.png'
import InputAdornment from "@material-ui/core/InputAdornment";
import Lock from '../../../assets/icons/lock.png'


const styles = theme => ({
    main: {
      width: "auto",
      display: "block",
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(400 + theme.spacing.unit * 2 * 2)]: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto"
      }
    },
    paper: {
      backgroundColor: "white",
      boxShadow: "none",
      marginTop: theme.spacing.unit * 12,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
        .spacing.unit * 3}px`
    },
    paper2: {
      backgroundColor: "white",
      boxShadow: "none",
      marginTop: theme.spacing.unit * 22,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
        .spacing.unit * 3}px`
    },
    form: {
      width: "85%", // Fix IE 11 issue.
      marginTop: theme.spacing.unit
    },
    submit: {
      padding: "10px",
      marginTop: "5px"
    },
    button1: {
      marginTop: theme.spacing.unit * 2,
      padding: "7px 23px 7px 23px",
      marginRight: "2.5%",
      marginTop: "5px"
    },
    button2: {
      marginTop: theme.spacing.unit * 2,
      padding: "7px 23px 7px 23px",
      marginLeft: "2.5%",
      marginTop: "5px"
    }
  });
  
  const theme = createMuiTheme({
    typography: {
      useNextVariants: true
    },
    palette: {
      primary: {
        main: "#00558D"
      },
      secondary: {
        main: "#585858"
      }
    }
  });
  
  class ForgetPassword extends React.Component {
    state = {
     email: '',
     code: '',
     password: '',
     confirmPassword: '',
     loading: false,
     isSendingCode: false,
     codeSent: false,
     isConfirming: false,
     confirmed: false,
     eErr: false,
     cErr: false,
     pErr: false,
     cpErr: false,
    };

    validation = () => {
      if(this.state.email.length === 0){
        this.setState({
          eErr: true
        })
      }
      else{
        this.setState({
          eErr: false,
  
        })
      }
    }

    handleChange = e => {
      this.setState({
        [e.target.name]: e.target.value
      })
    }
    cancelHandle = e => {
      e.preventDefault()
      this.props.history.push('/')
    }

    emailSubmitHandle = async e => {
      e.preventDefault()
      this.validation();
      console.log(this.state.email)
      this.setState({
        isSendingCode: true,
        loading: true
      })
      try {
        await Auth.forgotPassword(this.state.email)
        .then(response => {
            console.log(JSON.stringify(response))
            this.setState({
                codeSent: true,
                loading: false
              })
            alert(response)
        })
        .catch(err => {
            this.setState({
                loading: false
              })
              alert(err.message);

        })
        
      }
      catch (e) {
       // alert(e.message);
        this.setState({
          isSendingCode: false,
          loading: false,
         // eErr:true
        })
      }
    }
    passwordSubmitHandle = async e => {
      e.preventDefault()
      if(this.state.password != this.state.confirmPassword){
        alert("password Mismatch")
      }
      else{
        this.setState({loading: true})
        try {
        await Auth.forgotPasswordSubmit(
          this.state.email,
          this.state.code,
          this.state.password
        )
        this.setState({loading: false})
        alert("Successfully changed your password")
        this.props.history.push('/')
        this.setState({confirmed: true})
        }
      catch(e){
        this.setState({loading: false})
        if(e.message){
        alert(e.message)
        }
        this.setState({isConfirming: false})
      }
    }
  }
    

  
    renderEmailConfirm() {
      const { classes } = this.props;
      return (
        <main className={classes.main}>
        <img className="solverse-logo" src={solverseLogo}/>
          <CssBaseline />     
          <Paper className={classes.paper2}>
          <MuiThemeProvider theme={theme}>
            <Typography variant="subtitle1" color="secondary">Enter your registered Email Address</Typography>   
              <form onSubmit={this.emailSubmitHandle} className={classes.form}>
                <FormControl margin="normal" required fullWidth>
                  {/* <TextField
                    autoFocus
                    error={this.state.eErr}
                    label="Email Address"
                    name="email"
                    onChange={this.handleChange}
                    margin="dense"
                    variant="outlined"
                  /> */}
                  <TextField
                  id="outlined-bare"
                  type="email"
                  name="email"
                  error={this.state.eErr}
                  // className={classes.textField}
                  margin="normal"
                  onChange={this.handleChange}
                  className={classNames(classes.textField, classes.dense)}
                  //defaultValue="Email Address"
                  placeholder="Email Address"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><img src={Email}></img></InputAdornment>,
                  }}
                />
                </FormControl>
                {this.state.loading ? <LinearProgress/>: ''}
                <Button
                disabled={this.state.disabled}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}>
                Submit
              </Button>
              </form>
            </MuiThemeProvider>
          </Paper>
          <img className="background-image" src={backgroundImage} />
        </main>
      );
    }

    renderChangePassword() {
      const { classes } = this.props;
      return (
        <main className={classes.main}>
        <img className="solverse-logo" src={solverseLogo}/>
          <CssBaseline />
          <Paper className={classes.paper}>
            <MuiThemeProvider theme={theme}>
              <form onSubmit={this.passwordSubmitHandle} className={classes.form}>
                <FormControl margin="normal" required fullWidth>
                <Typography align="center" variant="body2" color="secondary" gutterBottom>A verification code has been sent to your Email</Typography>
                  {/* <TextField
                    autoFocus
                    onClick={this.errorCorrection}
                    error={this.state.eErr}
                    type="text"
                    label="Confirmation Code"
                    name="code"
                    onChange={this.handleChange}
                    className={classNames(classes.textField, classes.dense)}
                    margin="dense"
                    variant="outlined"
                  /> */}
                  <TextField
                  id="outlined-bare"
                  type="text"
                  name="code"
                  error={this.state.eErr}
                  // className={classes.textField}
                  margin="normal"
                  onChange={this.handleChange}
                  className={classNames(classes.textField, classes.dense)}
                  //defaultValue="Email Address"
                  placeholder="Confirmation Code"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><img src={Email}></img></InputAdornment>,
                  }}
                />
                  {/* <TextField
                    onClick={this.errorCorrection}
                    error={this.state.eErr}
                    type="password"
                    label="New Password"
                    name="password"
                    onChange={this.handleChange}
                    className={classNames(classes.textField, classes.dense)}
                    margin="dense"
                    variant="outlined"
                  /> */}
                  <TextField
                  id="outlined-bare"
                  margin="normal"
                  onChange={this.handleChange}
                  onClick={this.errorCorrection}
                  error={this.state.pErr} 
                  //defaultValue="Email Address"
                  placeholder="New Password"
                  name="password"
                  type={this.state.showPassword ? "text" : "password"}
                  className={classNames(classes.textField, classes.dense)}
                  variant="outlined"
                  InputProps={{
                  startAdornment:(<InputAdornment position="start"><img src={Lock}></img></InputAdornment>),
                  
                  // endAdornment: (
                  //   <InputAdornment position="end">
                  //     <IconButton
                  //       aria-label="Toggle password visibility"
                  //       onClick={this.handleClickShowPassword}
                  //     >
                  //       {this.state.showPassword ? (
                  //         <VisibilityOff />
                  //       ) : (
                  //         <Visibility />
                  //       )}
                  //     </IconButton>
                  //   </InputAdornment>
                  // )
                }}
                />
                  {/* <TextField
                    onClick={this.errorCorrection}
                    error={this.state.eErr}
                    type="password"
                    label="Confirm Password"
                    name="confirmPassword"
                    onChange={this.handleChange}
                    className={classNames(classes.textField, classes.dense)}
                    margin="dense"
                    variant="outlined"
                  /> */}
                  <TextField
                  id="outlined-bare"
                  margin="normal"
                  onChange={this.handleChange}
                  onClick={this.errorCorrection}
                  error={this.state.cpErr} 
                  //defaultValue="Email Address"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  type={this.state.showPassword ? "text" : "password"}
                  className={classNames(classes.textField, classes.dense)}
                  variant="outlined"
                  InputProps={{
                  startAdornment:(<InputAdornment position="start"><img src={Lock}></img></InputAdornment>),
                  
                  // endAdornment: (
                  //   <InputAdornment position="end">
                  //     <IconButton
                  //       aria-label="Toggle password visibility"
                  //       onClick={this.handleClickShowPassword}
                  //     >
                  //       {this.state.showPassword ? (
                  //         <VisibilityOff />
                  //       ) : (
                  //         <Visibility />
                  //       )}
                  //     </IconButton>
                  //   </InputAdornment>
                  // )
                }}
                />
                  {/* <TextField
                    onClick={this.errorCorrection}
                    error={this.state.eErr}
                    label="Email Address"
                    name="email"
                    onChange={this.handleChange}
                    className={classNames(classes.textField, classes.dense)}
                    margin="dense"
                    variant="outlined"
                  /> */}
                </FormControl>
                {this.state.loading ? <LinearProgress/>: ''}
                <div className="align">
                <Button
                onClick ={this.cancelHandle}
                disabled={this.state.disabled}
                variant="contained"
                color="primary"
                className={classes.button1}
              >
              Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button2}
              >
              Submit
              </Button>
              </div>
              </form>
            </MuiThemeProvider>
          </Paper>
          <img className="background-image" src={backgroundImage} />
        </main>
      );
    }

    render(){
      return(
        <div>
          {!this.state.codeSent ? this.renderEmailConfirm() :
          this.renderChangePassword()} :

        </div>
        // <div>
        // {this.renderChangePassword()}
        // </div>
      )
    }
  }

  
  ForgetPassword.propTypes = {
    classes: PropTypes.object.isRequired
  };
  
  export default withStyles(styles)(ForgetPassword);
  