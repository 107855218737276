import React,{useContext} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import {API_URL, ACTION_DELETE, MENU_USER, LOCALSTORAGEKEY_SUBMENU, MENU_PROJECT, LOCALSTORAGEKEY_MAINMENU} from '../../utils/constants'
import { Auth } from "aws-amplify";
import {
  withStyles,
  MuiThemeProvider,
createMuiTheme
} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

import LinearProgress from '@material-ui/core/LinearProgress';
import Homedetails from '../homedetails/index'
import MenuContext from '../../context/menuContext'

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Cupcake', 305, 3.7),
  createData('Donut', 452, 25.0),
  createData('Eclair', 262, 16.0),
  createData('Frozen yoghurt', 159, 6.0),
  createData('Gingerbread', 356, 16.0),
  createData('Honeycomb', 408, 3.2),
];

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name',width:"20%" },
  { id: 'description', numeric: false, disablePadding: false, label: 'Description',width:"60%" },
  { id: 'members', numeric: true, disablePadding: false, label: 'Members',width:"30%" },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status',width:"10%" },
];
const userheadRows = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name',width:"50%" },
  { id: 'description', numeric: false, disablePadding: false, label: 'Email',width:"40%" },
  { id: 'members', numeric: true, disablePadding: false, label: 'Plan',width:"10%" },
];

function EnhancedTableHead(props) {
  const menu = useContext(MenuContext);

  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  const getRowData = () => {
    if(localStorage.getItem(LOCALSTORAGEKEY_MAINMENU) === MENU_USER) { 
      return userheadRows
    }
    else {
      return headRows
    }
  }
  return (
    <TableHead className={classes.head}>
      <TableRow className={classes.rhead}>
        {getRowData().map(row => (
          <TableCell
            key={row.id}
            align={'left'}
            padding={row.padding}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
              {orderBy === row.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const theme = createMuiTheme({
  shadows: ["none"],
  backgroundColor:'#ecf3f5',
  
});
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height:'100%',
    backgroundColor:'#ecf3f5'
  },
  paper: {
    width: '100%',
    height:'100%',
    marginBottom: theme.spacing(2),
    display:'flex',
    flexDirection:'column',
    backgroundColor:'#ecf3f5'
  },
  head: {
    backgroundColor:'white',
  },
  rhead: {
    height:'70px'
  },
  frow: {
    display:'flex'
  },
  table: {
    minWidth: 900,
  },
  tableWrapper: {
    //overflowX: 'auto',
  },
  topbar: {
    display:'flex',
    alignItems:'space-between',
    margin:'10px 10px 20px 10px',
    justifyContent:'space-between'
  },
  tabTitle: {
    color:'#4d4d4d',
    fontSize:'16pt',
    fontWeight:400,
    textAlign:'left',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    marginTop:'10px'
  
  },
  lab : {
    width:'250pt',
    '&::placeholder': {
      color:'#BEBFC0'
    },
    sea: {
      color:'secondary'
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rowname: {
    backgroundColor: "#F8FBFC",
    height: '70px'
  },
  rowse:{
    backgroundColor: "#fcfdfe",
    height: '70px'
  },
  fileparent: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginLeft:10,
    alignItems: "center"
  },
  foldericon: {
    width: "100%",
    height: "100%",
    borderRadius:'50%',
    // backgroundColor:'grey'
  },
  foldericonparent:{
    marginBottom: "3px",
    marginRight: "10px",
    width: "40px",
    height: "40px"
  }
}));
let mainList = []
export default function Home(props) {
  const classes = useStyles();
  const menu = useContext(MenuContext);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [Datas,setData] = React.useState([])
  const [isLoading,setIsLoading] = React.useState(false)
  const [isDetail,setIsDetail] = React.useState(false)
  const [selectedProject,setSelectedProject] = React.useState(null)
  const [searchFieldValue,setSearchFieldValue] = React.useState('')
  React.useEffect(() => {
    setIsLoading(true)
    try {
      // console.log('menu.selectedMenu ' + JSON.stringify(menu.selectedMenu))
      let apiUrl = `${API_URL}projects`;
      if(localStorage.getItem(LOCALSTORAGEKEY_MAINMENU) === MENU_USER) {
        apiUrl = `${API_URL}projects/admin/users`;
      }
    Auth.currentAuthenticatedUser().then(user => {
    fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        accessToken: user.signInUserSession.accessToken.jwtToken
      }
    })
      .then(response =>
        response.json().then(json => {
          console.log('json data' + JSON.stringify(json))
          setIsLoading(false)
          console.log('props.history ' + JSON.stringify(props.history))
          if(json.status === 401) {
            props.history.push("/")
            return
          }
          const listdata = []
          console.log('json => ' + JSON.stringify(json))
          json.data.forEach(data => {
            listdata.push({
              name:data.name,
              Id : data.Id,
              description : data.description,
              usersCount : data.usersCount,
              imageUrl:data.imageUrl,
              status:data.status
            })
          })
          mainList = listdata;
          setData(listdata)
          setRowsPerPage(listdata.length)
        })
      )
      .catch(error => {
        setIsLoading(false)
        console.log("error => " + error);
      });
    })
  }
  catch(e) {
    props.history.push("/")
  }
  },[])
  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }


  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeDense(event) {
    setDense(event.target.checked);
  }
  function disableDetailView() {
    setIsDetail(false)
    setSelectedProject(null)
  }
  function suspendProject(projectId,emailid,status) {
    console.log('suspendProject ' + projectId)
    setIsLoading(true)
    Auth.currentAuthenticatedUser().then(user => {
      if(localStorage.getItem(LOCALSTORAGEKEY_SUBMENU) === ACTION_DELETE) {
        console.log('action delete')
        if(localStorage.getItem(LOCALSTORAGEKEY_MAINMENU) === MENU_PROJECT) {
          console.log('MENU_PROJECT')

          fetch(`${API_URL}projects/delete`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              accessToken: user.signInUserSession.accessToken.jwtToken
            },
            body: JSON.stringify({
              projectId:projectId,
          })
          })
            .then(response =>
              response.json().then(json => {
                setIsLoading(false)
                if(json.status === "success") {
                  let listdata = [...Datas]
                  listdata = listdata.filter(x => x.Id !== projectId)
                  mainList = listdata
                  setData(listdata);
                  setIsDetail(false)
                  setSelectedProject(false)
                }
                else {
                  alert('Failed to delete')
                }
              })
            )
            .catch(error => {
              setIsLoading(false)
              console.log("error => " + error);
            });
        }
        else {
          console.log('MENU_USER')
          fetch(`${API_URL}projects/admin/delete`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              accessToken: user.signInUserSession.accessToken.jwtToken
            },
            body: JSON.stringify({
              projectId:projectId,
              email:emailid
          })
          })
            .then(response =>
              response.json().then(json => {
                setIsLoading(false)
                if(json.status === "success") {
                  let listdata = [...Datas]
                  listdata = listdata.filter(x => x.Id !== projectId)
                  mainList = listdata
                  setData(listdata);
                  setIsDetail(false)
                  setSelectedProject(false)
                }
                else {
                  alert('Failed to delete')
                }
              })
            )
            .catch(error => {
              setIsLoading(false)
              console.log("error => " + error);
            });
        }
      }
      else {
        console.log('changestatus')
        fetch(`${API_URL}projects/admin/changeStatus`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            accessToken: user.signInUserSession.accessToken.jwtToken
          },
          body: JSON.stringify({
            projectId:projectId,
            statusValue:status=='active'?"suspend":"active",
            recordType: localStorage.getItem(LOCALSTORAGEKEY_MAINMENU) === MENU_USER ? 'user' : 'project'
        })
        })
          .then(response =>
            response.json().then(json => {
              setIsLoading(false)
              if(json.status === "success") {
                let listdata = [...Datas]
              let modData = listdata.findIndex(x => x.Id == projectId)
               console.log("MOdddddddddddddddd",listdata[modData]);
                listdata[modData].status = status=='active'?"suspend":"active"
                mainList = listdata
                setData(listdata);
                setIsDetail(false)
                setSelectedProject(false)
              }
              else {
                alert('Failed to change status')
              }
            })
          )
          .catch(error => {
            setIsLoading(false)
            console.log("error => " + error);
          });
      }
   
    })

  }



  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const filterList = (filterString) => {
    console.log('filterString ' + JSON.stringify(filterString))
    let newItems = mainList.filter(x =>  x.name.toLowerCase().includes(filterString.toLowerCase()))
    console.log('newItems '+ JSON.stringify(newItems))
    setData(newItems)
    return newItems;
  }
  const getTitle = () => {
    const title = localStorage.getItem(LOCALSTORAGEKEY_SUBMENU) + ' ' + localStorage.getItem(LOCALSTORAGEKEY_MAINMENU);
    return title;
  }
  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.topbar}>
            <div className={classes.tabTitle}>{getTitle()}</div>
            {!isDetail ? <TextField
            label="Search"
            value={searchFieldValue}
            onChange={(event) => {
              setSearchFieldValue(event.target.value)
              console.log('event.value ' + event.target.value)
              const newlist = filterList(event.target.value)
              
              console.log('newlist',newlist)
            }}
            InputProps={{
              classes: { input: classes.lab},
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <CloseIcon className={classes.sea} onClick = {() => {
                        const newlist = filterList("")
                        setSearchFieldValue('')
                        setData(newlist)
                      }}/>
                  </IconButton>
                </InputAdornment>
              )
            }}
          /> : ''}
            </div>
       
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
       {isLoading ? <LinearProgress/>: ''}
       {isDetail ? <Homedetails suspendProject={suspendProject} goBackToList={disableDetailView} name={selectedProject.name} description={selectedProject.description} status={selectedProject.status}
       projectid = {selectedProject.Id}/> :( <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            id="table"
          >
          <colgroup>
                <col style={{ width: "20%" }} />
                <col style={{ width: "40%" }} />
                <col style={{ width: "10%" }} />
              </colgroup>
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {console.log("Datas",Datas)}
              { stableSort(Datas, getSorting(order, orderBy)) 
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  console.log('data_Search  ' + JSON.stringify(row))
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    
                    <TableRow
                      hover
                      className={index % 2 === 0 ? classes.rowname : classes.rowse}
                      onClick={event => {
                        setIsDetail(true)
                        console.log('data _row ' + JSON.stringify(row))
                        setSelectedProject(row)
                      }}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.Id}
                      // selected={isItemSelected}
                    >
                      <TableCell
                          style={{ cursor: "pointer" }}
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          <div className={classes.fileparent}>
                            <div className={classes.foldericonparent}>
                                <img
                                src={row.imageUrl}
                                  className={classes.foldericon}
                                  alt=""
                                />
                            </div>
                            <div>{row.name.substring(0, 50)}</div>
                          </div>
                        </TableCell>
                      <TableCell align="left">{row.description}</TableCell>
                      <TableCell align="left">{row.usersCount}</TableCell>
                      {localStorage.getItem(LOCALSTORAGEKEY_MAINMENU) === MENU_PROJECT && 
                      <TableCell align="left">{row.status}</TableCell>}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )} 
            </TableBody>
          </Table>
       </div>)}
      </Paper>
    </div>
    </MuiThemeProvider>
  );
}

