import React,{useContext}  from 'react';
import { makeStyles, mergeClasses } from '@material-ui/styles';
import Button from "@material-ui/core/Button";
import { ACTION_DELETE, LOCALSTORAGEKEY_SUBMENU,LOCALSTORAGEKEY_MAINMENU,MENU_PROJECT,ACTION_UNSUSPEND } from "../../utils/constants";

import MenuContext from '../../context/menuContext'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      backgroundColor:'white',
      display:'flex',
      flexDirection:'column',
      alignItems:'start',
      marginLeft:'10px',
      marginBottom:'10px',
      height:'80%',
      paddingLeft:25,
      paddingTop:25,
      marginRight:'10px',
      paddingRight:25,
    },
    heading : {
        color: '#999999',
        fontWeight: '500',
        fontSize: '12pt',
        marginBottom:5
    },
    data: {
        color:'#AFAFAF',
        fontSize:'13pt',
        fontWeight:'300',
        marginBottom:50,
    },
    abu: {
      display:'flex',
      justifyContent:'center',
      width:'100%',
      flexGrow:1,
      alignItems:'flex-end',
      marginBottom:'20pt'

    },
    sus: {
      backgroundColor:'#18558d',
      width:'150px',
      height:'50px',
      color:'white',
      marginRight:'10px'
    },
    can : {
      backgroundColor:'#c4cace',
      width:'150px',
      height:'50px',
      fontFamily:'Roboto',
      fontWeight:'500',
      border:'none',
      color:'#fff',
      cursor:'pointer',
      marginRight:'10px'
    },
    csvButton:{
      width:'150px',
      height:'50px',
      backgroundColor:'#00548C',
      fontFamily:'Roboto',
      fontWeight:'500',
      border:'none',
      color:'#fff',
      cursor:'pointer',
      marginRight:'10px'
    }

  }));
  export default function Homedetails(props) {
    const menu = useContext(MenuContext);
    const classes = useStyles();
    const [deleteDialogopen, setdeleteDialogopen] = React.useState(false);
    
    
    
    const handleDeleteDialogClose = () => {
      setdeleteDialogopen(false);
    };
    const handleDeleteDialogClickOpen = () => {
      setdeleteDialogopen(true);
    };
    return(
        <div className={classes.root}>
        <div className={classes.heading}>{localStorage.getItem(LOCALSTORAGEKEY_MAINMENU) === MENU_PROJECT? 'Project Name' : 'User Name'}</div>
        <div className={classes.data}>{props.name}</div>
        <div className={classes.heading}>{localStorage.getItem(LOCALSTORAGEKEY_MAINMENU) === MENU_PROJECT? 'Project Description' : 'Email Address'}</div>
        <div className={classes.data}>{props.description}</div>
        <div className={classes.abu}>  
        {/* <button  className={classes.csvButton} onClick={localStorage.setItem(LOCALSTORAGEKEY_SUBMENU,ACTION_UNSUSPEND),handleDeleteDialogClickOpen}>
              UNSUSPEND
            </button> */}
        <button  className={classes.csvButton} onClick={handleDeleteDialogClickOpen}>
            {localStorage.getItem(LOCALSTORAGEKEY_SUBMENU) === ACTION_DELETE ? 'DELETE' :props.status ==='active' ? 'SUSPEND' : 'UNSUSPEND'}

              {/* SUSPEND */}
            </button>
            <button  className={classes.can} onClick={() => {
                          props.goBackToList()
                        }}>
              BACK
            </button>      
        </div>

        <div id="Delete">
                  <Dialog
                    fullWidth
                    maxWidth="xs"
                    open={deleteDialogopen}
                    onClose={handleDeleteDialogClose}
                    // PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                  >
                    <DialogTitle
                      style={{ cursor: "move" }}
                      id="draggable-dialog-title"
                    >
                      Confirm Delete
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Are you sure you want to {localStorage.getItem(LOCALSTORAGEKEY_SUBMENU) === ACTION_DELETE ? 'delete' :props.status ==='active' ?  'suspend' :'unsuspend'} the selected item(s)?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleDeleteDialogClose} color="primary">
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          props.suspendProject(props.projectid,props.description,props.status)
                        }}
                        color="primary"
                      >
                        Ok
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>



        </div>
    )
}