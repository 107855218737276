//DEVELOPMENT
// export const API_THREADEDDISCUSSION_S3BUCKET = 'https://solverse-threaded-discussion-assets.s3.us-east-2.amazonaws.com/public/'
// export const S3BUCKET = 'solverse-threaded-discussion-assets'
// export const bucketName = 'solverseassets';
// export const API_URL = "http://localhost:3001/";
// export const ONLYOFFICE_BACKURL = 'http://localhost:3000/dashboard'
 //export const API_URL = "http://localhost:3001/"
  export const API_URL = "https://solverseplatform.cabotprojects.com/";
//export const API_URL = "http://ec2-3-81-19-80.compute-1.amazonaws.com:3001/";

//DEVELOPMENT/PRODUCTION
export const COGNITOUSERGROUP = "SuperAdministrators"
// export const API_URL = "http://localhost:3001/"; //DEV
//QA
//export const API_URL = "http://ec2-3-81-19-80.compute-1.amazonaws.com:3001/"; //PROD
export const bucketName = "solverseassets-prod";
export const API_THREADEDDISCUSSION_S3BUCKET =
  "https://solverse-threaded-discussion-assets-prod.s3.us-east-2.amazonaws.com/public/";
export const S3BUCKET = "solverse-threaded-discussion-assets-prod";
export const ONLYOFFICE_BACKURL =
  "http://solversewebsite.s3-website.us-east-2.amazonaws.com/Files";
// export const ONLYOFFICE_BACKURL =
//   "http://solversedev.s3-website.us-east-2.amazonaws.com/Files";
// export const ONLYOFFICE_BACKURL = 'http://solverseqa.s3-website-us-east-1.amazonaws.com/Files'
export const LOCALSTORAGEKEY_TOKEN = "localstoragekey_token"
export const STATUS_FAILURE = "failure";
export const ProjectId = "2by955DeyFowkzNb4QNt4d";
export const API_ERROR_CONSTANT = "401";

export const LOCALSTORAGEKEY_MAINMENU = "mainmenu";
export const LOCALSTORAGEKEY_SUBMENU = "submenu";

export const MENU_USER = "Users"
export const MENU_PROJECT = "Projects"
export const MENU_REPORT = "reportsmenu"

export const ACTION_SUSPEND = "Suspend"
export const ACTION_UNSUSPEND = "UnSuspend"
export const ACTION_DELETE = "Delete"
