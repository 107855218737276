import React from "react";
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme
} from "@material-ui/core/styles";
import backgroundImage from "../../assets/images/backgroundimage.png";
import solverseLogo from "../../assets/icons/solverseBlue.png";
import Button from "@material-ui/core/Button";
import { ACTION_SUSPEND,LOCALSTORAGEKEY_MAINMENU,LOCALSTORAGEKEY_SUBMENU,MENU_USER,MENU_PROJECT, ACTION_DELETE,MENU_REPORT } from "../../utils/constants";
import { Auth } from "aws-amplify";
import  MenuContext  from '../../context/menuContext'

const styles = theme => ({
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 2 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  
  cendiv: {
    display:'flex',
    height:'100vh',
    flexDirection:"column",
    justifyContent:'center'
  },
  but: {
    backgroundColor:'#18558d',
    width:'345px',
    height:'50px',
    marginBottom: 10,
    color: 'white',
    border: '1px solid #2A9FD8',
    '&:hover': {
      backgroundColor:'#18558d'
    }
  },
  paper: {
    backgroundColor: "white",
    boxShadow: "none",
    marginTop: theme.spacing.unit * 12,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  form: {
    width: "85%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  termsText: {
    fontSize: "12.7px",
    paddingTop: "3%",
    color: "#4d5356"
  },
  signupText: {
    //fontFamily:"Roboto-Regular",
    fontSize: "14px",
    paddingTop: "3%",
    color: "#4d5356"
  },
  mainHead: {
    //fontFamily: 'Roboto-Medium',
    color: "rgba(76, 74, 74, 0.87)",
    fontSize: "26px",
    fontWeight: "bold",
    letterSpacing: "-0.34px",
    lineHeight: "29px"
  },
  submit: {
    marginTop: "10px",
    padding: "12px"
  },
  rememberMeRectangle: {
    width: "17px",
    height: "17px"
  },
  textField: {
    width: "300px",
    height: "42px",
    marginTop: "0px"
    //backgroundColor:"#fff"
  },
  boline: {
    /* margin-top: 4%; */
    height: 25,
    width: 1,
    backgroundColor: "black",
    marginRight: "2%"
  },
  boheading: {
    letterSpacing: "3px",
    fontWeight: 500,
    fontSize: "larger",
    color: "#666666"
  },
  top: {
    display: "flex",
    /* align-content: center; */
    alignItems: "center",
    marginTop: "2%",
    marginLeft: "4%"
  },
  imgclass: {
    marginRight: "2%"
  }
});
const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: "#00558D"
    },
    secondary: {
      main: "#838a8d"
    }
  }
});
class SubMenu extends React.Component {
  state = {};
  static contextType = MenuContext

  componentWillMount() {
    try {
      if(!this.props.history.location.state) {
        this.history.push("/")
      }
      Auth.currentAuthenticatedUser().then(user => {
        const token = user.signInUserSession.accessToken.jwtToken
        if(!token) {
          this.props.history.push("/")
        }
      })
    }
    catch(e) {
      this.props.history.push("/")
    }
  
  }
  render() {
    const { classes } = this.props;
    // console.log('this.props.history ' + JSON.stringify(this.props.history.location.state))
    let menuItems = null;
    if(this.props.history.location.state) {
      menuItems = this.props.history.location.state.menuItems
    }
    // const { menuItems } = this.props.history.location.state;
    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.top}>
            <img className={classes.imgclass} src={solverseLogo} alt="" />
            <div className={classes.boline} />
            <div className={classes.boheading}>SUPER ADMIN</div>
          </div>
          <div className={classes.main}>
            <div className={classes.cendiv}>
              <Button className={classes.but} onClick={() => {
                const menu = this.context
                if (localStorage.getItem(LOCALSTORAGEKEY_MAINMENU) === MENU_USER || localStorage.getItem(LOCALSTORAGEKEY_MAINMENU) === MENU_PROJECT) {
                  console.log('menu ' + JSON.stringify(menu))
                  localStorage.setItem(LOCALSTORAGEKEY_SUBMENU,ACTION_SUSPEND)
                  menu[LOCALSTORAGEKEY_SUBMENU] = ACTION_SUSPEND
                  this.props.history.push("home")
                }
                else if(menu.selectedMenu === MENU_REPORT) {
                  //reports
                  this.props.history.push("projectReports")
                }
                else{
                  
                }

              }}>{menuItems && menuItems.length > 0 ? menuItems[0] : 'SUSPEND'}</Button>
              <Button className={classes.but} onClick={() => {
                const menu = this.context
                if (localStorage.getItem(LOCALSTORAGEKEY_MAINMENU) === MENU_USER || localStorage.getItem(LOCALSTORAGEKEY_MAINMENU) === MENU_PROJECT) {
                  console.log('menu ' + JSON.stringify(menu))
                  localStorage.setItem(LOCALSTORAGEKEY_SUBMENU,ACTION_DELETE)
                  menu[LOCALSTORAGEKEY_SUBMENU] = ACTION_DELETE
                  this.props.history.push("home")
                }else if(menu.selectedMenu === MENU_REPORT) {
                  //reports
                  this.props.history.push("userReports")
                }
                else {
                  //reports
                }

              }}>{menuItems && menuItems.length > 1 ? menuItems[1] : 'DELETE'}</Button>
              <Button className={classes.but}
                onClick={() => {
                  const menu = this.context

                  if (localStorage.getItem(LOCALSTORAGEKEY_MAINMENU) === MENU_USER || localStorage.getItem(LOCALSTORAGEKEY_MAINMENU) === MENU_PROJECT) {
                    this.props.history.goBack()
                  }else if(menu.selectedMenu === MENU_REPORT) {
                    //reports
                    this.props.history.push("paymentReports")
                  }
                  else {
                    //reports
                  }
                }
                }
              >{menuItems && menuItems.length > 2 ? menuItems[2] : 'BACK'}

              </Button>
            </div>
            <img className="background-image" src={backgroundImage} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SubMenu);
