export default {
    // cognito: {
    //     REGION: "us-east-1",
    //     USER_POOL_ID: "us-east-1_a8RnZU5q8",
    //     APP_CLIENT_ID: "19m9tgutcv0reb5h6rhrf6j",
    //     IDENTITY_POOL_ID: "us-east-1:9d0d3d61-f33d-4fdd-bf33-382d5dbf86de"
    // }
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_FmZd2rbBY",
        APP_CLIENT_ID: "t9smh5gc3mbk42b2o0lv76c7l",
        IDENTITY_POOL_ID: "arn:aws:cognito-idp:us-east-1:611459592997:userpool/us-east-1_a8RnZU5q8"
    }
};