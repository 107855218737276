import React from "react";
import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import LoginPage from "../auth/signin/index";
import ForgetPassword from "../auth/signin/forgetPassword";
import MainMenu from "../mainmenu/index";
import SubMenu from "../submenu/index";
import MiniDrawer from '../../layouts/admin'
import UserReports from '../reports/UserReports'
import ProjectReports from '../reports/ProjectReports'
import { MenuProvider } from "../../context/menuContext";
import { MENU_PROJECT } from "../../utils/constants";
import { LOCALSTORAGEKEY_SUBMENU,ACTION_SUSPEND } from "../../utils/constants";

import PaymentReports from '../reports/PaymentReports'
function App() {
  return (
    <MenuProvider value={{selectedMenu : MENU_PROJECT,LOCALSTORAGEKEY_SUBMENU:ACTION_SUSPEND}}>
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/forgotpassword" component={ForgetPassword} />
          <Route exact path="/menu" component={MainMenu} />
          <Route exact path="/submenu" component={SubMenu} />
          <Route exact path="/home" component={MiniDrawer} />
          <Route exact path="/UserReports" component={MiniDrawer} />
          <Route exact path="/ProjectReports" component={MiniDrawer} />
          <Route exact path="/PaymentReports" component={MiniDrawer} />
        </Switch>
      </BrowserRouter>
    </div>
    </MenuProvider>
  );
}

export default App;
