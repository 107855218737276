import React,{useContext} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import Button from "@material-ui/core/Button";
import { Auth } from "aws-amplify";
import {API_URL, LOCALSTORAGEKEY_MAINMENU, MENU_USER, LOCALSTORAGEKEY_SUBMENU} from '../../utils/constants'
// import Excel from 'exceljs';
import {
  withStyles,
  MuiThemeProvider,
createMuiTheme
} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import LinearProgress from '@material-ui/core/LinearProgress';
import Homedetails from '../homedetails/index'
import MenuContext from '../../context/menuContext'
import ReactToExcel from 'react-html-table-to-excel'

// import ReactExport from "react-export-excel";
import { sectionFooterPrimaryContent } from '@aws-amplify/ui';
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Cupcake', 305, 3.7),
  createData('Donut', 452, 25.0),
  createData('Eclair', 262, 16.0),
  createData('Frozen yoghurt', 159, 6.0),
  createData('Gingerbread', 356, 16.0),
  createData('Honeycomb', 408, 3.2),
];

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Users',width:"30%" },
  { id: 'totalprojects', numeric: true, disablePadding: false, label: 'Total Projects',width:"30%" },
  { id: 'Name', numeric: false, disablePadding: false, label: 'Projects',width:"20%" },
  { id: 'Role', numeric: false, disablePadding: false, label: 'Role',width:"30%" },
  
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };


  
  return (
    <TableHead className={classes.head}>
      <TableRow className={classes.rhead}>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={'left'}
            padding={row.padding}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
              {orderBy === row.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const theme = createMuiTheme({
  shadows: ["none"],
  backgroundColor:'#ecf3f5',
  
});
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height:'100%',
    backgroundColor:'#ecf3f5'
  },
  paper: {
    width: '100%',
    height:'100%',
    marginBottom: theme.spacing(2),
    display:'flex',
    flexDirection:'column',
    backgroundColor:'#ecf3f5'
  },
  head: {
    backgroundColor:'white',
  },
  rhead: {
    height:'70px'
  },
  frow: {
    display:'flex'
  },
  table: {
    minWidth: 900,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  topbar: {
    display:'flex',
    alignItems:'space-between',
    margin:'10px 10px 20px 10px',
    justifyContent:'space-between'
  },
  tabTitle: {
    fontStyle:'Roboto',
    color:'#4d4d4d',
    fontSize:'16pt',
    fontWeight:400,
    textAlign:'left',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    marginTop:'10px'
  
  },
  lab : {
    width:'250pt',
    '&::placeholder': {
      color:'#BEBFC0'
    },
    sea: {
      color:'secondary'
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rowname: {
    backgroundColor: "#F8FBFC",
    height: '70px',
  },
  subrow: {
    backgroundColor: "#F8FBFC",
    height: '30px',
    textAlign:'center',
  },
  rowse:{
    backgroundColor: "#fcfdfe",
    height: '70px'
  },
  fileparent: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginLeft:10,
    alignItems: "center"
  },
  foldericon: {
    width: "100%",
    height: "100%",
    borderRadius:'50%',
    backgroundColor:'grey'
  },
  foldericonparent:{
    marginBottom: "3px",
    marginRight: "10px",
    width: "40px",
    height: "40px"
  },
  csvButton:{
    width:'90px',
    height:'35px',
    backgroundColor:'#ff7424',
    fontFamily:'Roboto',
    fontWeight:'500',
    border:'none',
    color:'#fff',
    cursor:'pointer'
  }
}));
let mainList = []
export default function UserReports(props) {
  const classes = useStyles();
  const menu = useContext(MenuContext);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [data,setData] = React.useState([])
  const [isLoading,setIsLoading] = React.useState(false)
  const [isDetail,setIsDetail] = React.useState(false)
  const [selectedProject,setSelectedProject] = React.useState(null)
  const [xlsData,setxlsData] = React.useState(null)
  const [isExcelDataLOaded,setIsExcelDataLOaded] = React.useState(false)
  const [printing,setPrinting] = React.useState([])


// const dataDB = [{
//   "createdTime": 1567507765840,
//   "dataValues": {
//     "defaultProject": [
//       {
//         "projectId": "k0518l25",
//         "projectName": "latest test case"
//       }
//     ],
//     "FullName": "Rasheeque",
//     "OnlyOffice": {
//       "Expires": "Expires",
//       "Password": "Pass",
//       "Token": "Token",
//       "UserName": "Test"
//     },
//     "projects": [
//       {
//       "Id": "k03ti93t",
//       "Name": "test1",
//       "Role": "Administrator"
//       },
//       {
//       "Id": "ksdsdsd",
//       "Name": "test6",
//       "Role": "Moderator"
//       }
//     ],
//     "UserSubscription": "None",
//     "UsuarioID": "16d6ff52-222c-4e27-93d7-6b82f55cf15f"
//   },
//   "EmailAddress": "rasheeque.c+245@cabotsolutions.com",
//   "Id": "16d6ff52-222c-4e27-93d7-6b82f55cf15f",
//   "recordStatus": "active",
//   "recordType": "user",
//   "type": "user"
// },{
//   "createdTime": 1567507765840,
//   "dataValues": {
//     "defaultProject": [
//       {
//         "projectId": "k0518l25",
//         "projectName": "latest test case"
//       }
//     ],
//     "FullName": "Hariharan k s",
//     "OnlyOffice": {
//       "Expires": "Expires",
//       "Password": "Pass",
//       "Token": "Token",
//       "UserName": "Test"
//     },
//     "projects": [
//       {
//       "Id": "k03ti93t",
//       "Name": "test2",
//       "Role": "Administrator"
//       },
//       {
//       "Id": "ksdsdsd",
//       "Name": "test3",
//       "Role": "Moderator"
//       },
//       {
//         "Id": "ksdsdsd",
//         "Name": "test4",
//         "Role": "Moderator"
//       }
//     ],
//     "UserSubscription": "None",
//     "UsuarioID": "16d6ff52-222c-4e27-93d7-6b82f55cf15f"
//   },
//   "EmailAddress": "rasheeque.c+245@cabotsolutions.com",
//   "Id": "16d6ff52-222c-4e27-93d7-6b82f55cf15f",
//   "recordStatus": "active",
//   "recordType": "user",
//   "type": "user"
// }]
  React.useEffect(() => {
    setIsLoading(true)
    try {
    Auth.currentAuthenticatedUser().then(user => {
    fetch(`${API_URL}reports/userReport`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        accessToken: user.signInUserSession.accessToken.jwtToken
      }
    })
      .then(response =>
        response.json().then(json => {
          console.log('json ' + JSON.stringify(json))
          setIsLoading(false)
          console.log('props.history ' + JSON.stringify(props.history))
          let dataDB = json
          if(json.status === 401) {
            props.history.push("/")
            return
          }
          //setProjects(json.Items.dataValues.projects)
          const listdata = []
          console.log('dataDB ' + JSON.stringify(dataDB))
          const DB = JSON.stringify(dataDB)
          dataDB.forEach((data,index) => {
            if(data.dataValues["projects"]){
          console.log("Projects",data.dataValues.projects)
          
            listdata.push({
              name:data.dataValues.FullName?data.dataValues.FullName:"",
              //Id : data.Id,
               projectName : data.dataValues.projects.Name?data.dataValues.projects.Name:"",
               projects : data.dataValues.projects?data.dataValues.projects:"",
              //projectRole: data.dataValues.projects[index].Role
              
            })}
          })
          mainList = listdata;
          console.log("ListData"+JSON.stringify(mainList))
          setData(listdata)
          setxlsData(listdata)
          setRowsPerPage(listdata.length)
          setIsExcelDataLOaded(true)
        })
      )
      .catch(error => {
        setIsLoading(false)
        console.log("error => " + error);
      });
    })
  }
  catch(e) {
    props.history.push("/")
  }
  },[])

  
  

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }


  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeDense(event) {
    setDense(event.target.checked);
  }
  function disableDetailView() {
    setIsDetail(false)
    setSelectedProject(null)
  }
 
  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const filterList = (filterString) => {
    console.log('mainList ' + JSON.stringify(mainList))
    console.log('filterString ' + JSON.stringify(filterString))

    let newItems = mainList.filter(x =>  x.name.toLowerCase().includes(filterString.toLowerCase()))
    console.log('newItems '+ JSON.stringify(newItems))
    return newItems;
  }


//   const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
//   const fileExtension = '.xlsx';
//   const exportToCSV = (csvData, fileName) => {
//     console.log("CSVData"+JSON.stringify(csvData))
//     const ws = XLSX.utils.json_to_sheet(csvData);
//     console.log("CSVData"+ JSON.stringify(ws))

//     const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
//     const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
//     const data = new Blob([excelBuffer], {type: fileType});
//     FileSaver.saveAs(data, fileName + fileExtension);
// }

const multiDataSet =() => {
      var DataP =[]
      var columns= [["Users", "Total_Projects", "Projects", "Role"]]
      let result

       let Print = data.map((row,index) => {


             
         result= row.projects.map((key,index)=>{    
          console.log("result",key)

          return columns.push([row.name,row.projects.length,key.Name,key.Role])

          //console.log("resultrow", [row.name,row.projects.length,key.Name,"kkk"])
           })
            return 

        })
        for(var i=0;i<columns.length;i++){
          DataP.push(columns[i].join(","))
        }
        var print = DataP.join("%0A")
        console.log("dataP",print)

        var a = document.createElement("a")
        a.href="data:attachment/csv, "+print
        a.target="_Blank"
        a.download="Report.csv"
        document.body.appendChild(a);
        a.click();
      };
      const getTitle = () => {
        const title = menu[LOCALSTORAGEKEY_SUBMENU] + ' ' + menu.selectedMenu;
        return title;
      }
//console.log("multiDataSet",multiDataSet)

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
        {/* <div className={classes.topbar}>
            <div className={classes.tabTitle}>{getTitle()}</div>
            {!isDetail ? <TextField
            label="Search"
            onChange={(event) => {
              console.log('event.value ' + event.target.value)
              const newlist = filterList(event.target.value)
              setData(newlist)
            }}
            InputProps={{
              classes: { input: classes.lab},
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon className={classes.sea} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          /> : ''}
            </div> */}
          <div className={classes.topbar}>
            <div className={classes.tabTitle}>Project Listing by User</div>
            {isExcelDataLOaded ? (
             <input type="button" className={classes.csvButton} onClick={(e) => multiDataSet()} value="CSV/XLS"/>
            // <ReactToExcel
            // table="data-excel"
            // sheet="sheet 1"
            // filename="Report"
            // buttonText="Export to CSV/XLS"
            // /> 
            
            
            ):""}
            </div>
       
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
       {isLoading ? <LinearProgress/>: ''}
       {isDetail ? <Homedetails goBackToList={disableDetailView} name={selectedProject.name} description={selectedProject.description}
       projectid = {selectedProject.Id}/> :( <div className={classes.tableWrapper}>
          <Table
          id="data-excel"
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
          <colgroup>
                <col style={{ width: "30%" }} />
                <col style={{ width: "30%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "30%" }} />
              </colgroup>
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  //console.log("rowProjects", row.projects)
                  
                  
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  
                  return (
                    <TableRow
                      //hover
                      className={index % 2 === 0 ? classes.rowname : classes.rowse}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.Id}
                      // selected={isItemSelected}
                    >
                      <TableCell
                          component="th"
                          scope="row"
                        >
                          {row.name}
                        </TableCell>
                       
                      <TableCell align="left">{data[index].projects?data[index].projects.length:""}</TableCell>
                       <TableCell align="left">
                       {row.projects.map((row,index)=>{
                         //console.log('rows!!!!!',row);
                         return(
                        <TableRow> {row.Name} </TableRow>

                         )
                       })}
                       </TableCell>
                      <TableCell align="left">
                        {row.projects.map((row,index)=>{
                          ///console.log('rows!!!!!',row);
                          return(
                          <TableRow> {row.Role} </TableRow>

                          )
                        })}
                       </TableCell>
                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
       </div>)}
      </Paper>
    </div>
    </MuiThemeProvider>
  );
}

