import Toolbar from "@material-ui/core/Toolbar";
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import InputBase from "@material-ui/core/InputBase";
import solverse from "../assets/icons/solverse.png";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { fade } from "@material-ui/core/styles/colorManipulator";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { Auth } from "aws-amplify";
import AvatarImg from '../assets/icons/avatarcolor.png'
import Logout from '../assets/icons/logout.png'

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  mainDiv: {
    //border:"dotted",
    height: "100vh"
  },
  boheading: {
    letterSpacing:'2px',
    fontWeight:500,
    fontSize:'larger',
    color:'white'
  },
  text: {
    fontSize: "14px"
  },
  root: {
    display: "flex",
    backgroundColor: "#fff",
    height: "100vh"
    // overflow: 'hidden',
  },
  colorChange: {
    backgroundColor: "#ECF3F5"
  },
  icon: {
    margin: theme.spacing.unit * 2
  },
  grow: {
    flexGrow: 1
  },
  // menuButton: {
  //   marginLeft: 12,
  //   marginRight: 36
  // },
  drawerPaper: {
    width: drawerWidth
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 3,
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    },
    display: "flex"
  },
  // toolbar: theme.mixins.toolbar,
  appBar: {
    background: "#052851",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    zIndex: theme.zIndex.drawer + 1,
    //border:"dotted",
    height: theme.spacing.unit * 8
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36,
    marginLeft: 12
  },
  hide: {
    display: "none"
  },
  projectName: {
    paddingLeft: "4%",
    whiteSpace: "nowrap"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1
    //padding: theme.spacing(3),
  },
  innercontent: {
    height: "100vh",
    backgroundColor: "#ecf3f5"
  },
  listicon: {
    maxWidth: "100%",
    maxHeight: "100%"
  },
  imgDiv: {
    width: "19px",
    height: "16px"
  },
  profileName: {
    padding: "7%",
    minWidth: "7em",
    textAlign: "center"
  },
  profileArrow: {
    padding: "0%",
    color: "white"
  },
  profileAvatar: {
    padding: "0%",
    fontSize: "2rem"
  },
  menuIcon: {
    padding: "0.5%"
  },
  solverseLogo: {
    width: "11em",
    paddingTop: "2.2%",
    paddingBottom: "2.2%",
    paddingLeft: "2%"
  },
  divi: {
    height: 20,
    width:1,
    margin: "2%",
    backgroundColor:'#405e7d'
  },
  sideIcons: {
    color: "rgba(0, 0, 0, 0.54)",
    /* display: inline-flex; */
    minWidth: "46px",
    flexShrink: "0",
    marginLeft: "10px"
  },
  sideText: {
    fontSize: "14px",
    cursor:'pointer',
    fontWeight:'500'
  },
  logout: {
    width: 20,
    height: 20,
    float: 'right'
  },
}));
function SolverseToolBar(props) {
  const classes = useStyles();
  const [anchorEl, setanchorEl] = React.useState(null);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [preview, setPreview] = React.useState(AvatarImg);
  const profileClick = event => {
    setanchorEl(event.currentTarget);
    // this.setState({anchorEl: event.currentTarget})
  };
  const profileClose = () => {
    setanchorEl(null);

    // this.setState({anchorEl: null})
  };
  function handleClickOpen() {
    setAlertOpen(true);
  }

  function handleClose() {
    setAlertOpen(false);
  }
  const logout = async () => {
    setanchorEl(null);
    // this.setState({anchorEl: null})
    localStorage.clear();

    await Auth.signOut();
    //alert("You have successfully Logout")
    props.loadLoginScreen();
  };
 
  return (
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={props.handleDrawerOpen}
        edge="start"
        className={classes.menuIcon}
        // className={clsx(classes.menuButton, {
        //   [classes.hide]: open,
        // })}
      >
        <MenuIcon />
      </IconButton>
      {/* <Typography
        className={classes.title}
        variant="h6"
        color="inherit"
        noWrap
      >
        SOLVERSE
      </Typography> */}
      <img src={solverse} className={classes.solverseLogo} alt="" />
      <div className={classes.divi}/>
      <div className={classes.boheading}>SUPER ADMIN</div>
      <div className={classes.grow} />
      <div className={classes.sectionDesktop}>
        {/* <div className={classes.profileName}>
          <small>{props.username}</small>
        </div> */}

        {/* <div className={classes.proPreview} onClick={handleClickOpen}>
          <img  className={classes.proPreview} src={props.displayImage ? props.displayImage: preview} alt="Preview" value=""/>
        </div> */}
        {/* <IconButton onClick={profileClick} className={classes.profileArrow}>
          <i className="material-icons">keyboard_arrow_down</i>
        </IconButton> */}
        {/* <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={profileClose}
        >
          <MenuItem onClick={props.profileClick}>Profile</MenuItem>
          <MenuItem onClick={handleClickOpen}>Logout</MenuItem>
        </Menu> */}
        <div className={classes.sideText} onClick={handleClickOpen}><img className={classes.logout} src={Logout}></img>
        </div>
      </div>
      <Dialog
        open={alertOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure want to logout ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={logout} color="primary">
            Yes
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Toolbar>
  );
}
export default SolverseToolBar;
