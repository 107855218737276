import React from "react";
import { Link } from "react-router-dom";
import "./signin.scss";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import TextField from "@material-ui/core/TextField";
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme
} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import "../../../utils/constants";
// import http from "../../../lib/http";
// import { API_URL } from "../../../utils/constants";
import { Auth } from "aws-amplify";
import validator from "email-validator";
import LinearProgress from "@material-ui/core/LinearProgress";
import backgroundImage from "../../../assets/images/backgroundimage.png";
import solverseLogo from "../../../assets/icons/solverseBlue.png";
import Email from "../../../assets/icons/email.png";
import Lock from "../../../assets/icons/lock.png";

// import '../../../assets/fonts/index.css'
require("typeface-roboto");

const styles = theme => ({
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 2 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    backgroundColor: "white",
    boxShadow: "none",
    marginTop: theme.spacing.unit * 12,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  form: {
    width: "85%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  termsText: {
    fontSize: "12.7px",
    paddingTop: "3%",
    color: "#4d5356"
  },
  signupText: {
    //fontFamily:"Roboto-Regular",
    fontSize: "14px",
    paddingTop: "3%",
    color: "#4d5356"
  },
  mainHead: {
    //fontFamily: 'Roboto-Medium',
    color: "rgba(76, 74, 74, 0.87)",
    fontSize: "26px",
    fontWeight: "bold",
    letterSpacing: "-0.34px",
    lineHeight: "29px"
  },
  submit: {
    marginTop: "10px",
    padding: "12px"
  },
  rememberMeRectangle: {
    width: "17px",
    height: "17px"
  },
  textField: {
    width: "300px",
    height: "42px",
    marginTop: "0px"
    //backgroundColor:"#fff"
  },
  boline: {
    /* margin-top: 4%; */
    height:25,
    width:1,
    backgroundColor:'black',
    marginRight: '2%',
  },
  boheading: {
    letterSpacing:'3px',
    fontWeight:500,
    fontSize:'larger',
    color:'#666666'
  },
  top: {
    display: 'flex',
    /* align-content: center; */
    alignItems: 'center',
    marginTop: '2%',
    marginLeft: '4%'
  },
  imgclass: {
   
    marginRight: '2%'
  }
});
const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: "#00558D"
    },
    secondary: {
      main: "#838a8d"
    }
  }
});

class SignIn extends React.Component {
  state = {
    email: "",
    password: "",
    showPassword: false,
    error: false,
    eErr: false,
    pErr: false,
    disabled: false
  };

  errorCorrection = () => {
    this.setState({
      nErr: false,
      eErr: false,
      pErr: false
    });
  };
  validation = () => {
    // if (!validator.validate(this.state.email)) {
    //   this.setState({
    //     eErr: true
    //   });
    // } else {
    //   this.setState({
    //     eErr: false
    //   });
    // }
    if (this.state.password.length === 0 && this.state.email.length === 0) {
      this.setState({
        pErr: true,
        eErr: true
      });
    } else if (this.state.password.length === 0) {
      this.setState({
        pErr: true
      });
    } else if (this.state.email.length === 0) {
      this.setState({
        eErr: true
      });
    }
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandle = async e => {
    // console.log("submitHandle ");
    //localStorage.clear();
    e.preventDefault();
    // localStorage.removeItem(LOCALSTORAGEKEY_PROJECTID)
    // localStorage.removeItem(LOCALSTORAGEKEY_PROJECTNAME)
    this.setState({
      disabled: true
    });

    
    this.validation();
    console.log(this.state.email, this.state.password);
    if (this.ValidateEmail(this.state.email)) {
      await Auth.signIn(this.state.email, this.state.password)
        .then(user => {
          this.setState({
            disabled: false
          });
          Auth.currentAuthenticatedUser().then(user => {
            
            const userGroup = user.signInUserSession.idToken.payload['cognito:groups'];
            if(userGroup !== undefined && userGroup.includes('SuperAdministrators')) {
              console.log('enter if')
              this.props.history.push("menu");
            }
          else {
            alert("Please enter a valid Email Id and Password!")
          }})
          })
          .catch(err => {
            this.setState({
              disabled: false
            });
            alert(err.message);
        });
      //this.setState({ pErrMsg:true})
      //alert("Please enter a valid Email Id and Password!")
    }

    //   this.props.history.push("dashboard");
    // } catch (e) {
    // }
    // const requestdata = {
    //   email: this.state.email,
    //   password: this.state.password
    // };
    // const promise = http.post(API_URL, requestdata);
    // promise.then(
    //   function(result) {
    //     this.props.history.push("dashboard")
    //     console.log("type" + result.success);
    //     if (!result.success) {
    //       alert(result.message);
    //     }
    //   },
    //   function(err) {
    //     console.log("Something broke" + JSON.stringify(err));
    //   }
    // );
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };
  ValidateEmail(mail) {
    if (
      /^\w+([\.-.+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
    ) {
      this.setState({
        eErr: false
      });
      return true;
    } else {
      alert("You have entered an invalid email address!");
      this.setState({
        eErr: true
      });
      return false;
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
      <div className={classes.top}>
      <img className={classes.imgclass} src={solverseLogo} alt="" />
      <div className={classes.boline}/>
      <div className={classes.boheading}>SUPER ADMIN</div>
      </div>
      <div className={classes.main}>
        {/* <CssBaseline /> */}
        <Paper className={classes.paper}>
          <MuiThemeProvider theme={theme}>
            <Typography variant="h5" className={classes.mainHead} gutterBottom>
              Welcome to Solverse
            </Typography>
            <Typography variant="subtitle1" color="secondary" gutterBottom>
              Sign into your account here
            </Typography>
            <form onSubmit={this.submitHandle} className={classes.form}>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  id="outlined-bare"
                  name="email"
                  error={this.state.eErr}
                  onClick={this.errorCorrection}
                  // className={classes.textField}
                  margin="normal"
                  onChange={this.handleChange}
                  className={classNames(classes.textField, classes.dense)}
                  //defaultValue="Email Address"
                  placeholder="Email Address"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={Email} />
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
               

                <TextField
                  id="outlined-bare"
                  margin="normal"
                  onChange={this.handleChange}
                  onClick={this.errorCorrection}
                  error={this.state.pErr}
                  //defaultValue="Email Address"
                  placeholder="Password"
                  name="password"
                  type={this.state.showPassword ? "text" : "password"}
                  className={classNames(classes.textField, classes.dense)}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={Lock} />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.handleClickShowPassword}
                        >
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl>
              {/* <Typography variant="body2" variant="subtitle1">
                <Link to="/forget-password" className="forgetText">
                  Forget password?
                </Link>
              </Typography> */}
              <Grid container>
                <Grid item xs>
                  <Typography variant="body2" variant="subtitle1">
                    <Link to="/forgotpassword" className="forgetText">
                      Forgot password?
                    </Link>
                  </Typography>
                </Grid>
                {/* <Grid item>
                  <img
                    className={classes.rememberMeRectangle}
                    src={rememberMeIcon}
                    alt=""
                  />
                  <label>Remember me</label>
                </Grid> */}
              </Grid>
              {this.state.disabled ? <LinearProgress /> : ""}
              <Button
                disabled={this.state.disabled}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                SIGN IN
              </Button>
              <Typography
                className={classes.termsText}
                color="secondary"
                align="center"
                variant="h5"
                gutterBottom
              >
                By signing up you agree to our{" "}
                <a href="/signin" className="link">
                  Terms and Conditions
                </a>
              </Typography>
              {/* <Typography
                className={classes.signupText}
                color="secondary"
                align="center"
                variant="h5"
                gutterBottom
              >
                New to Solverse?{" "}
                <a href="/signup" className="link">
                  Sign Up
                </a>{" "}
              </Typography> */}
            </form>
          </MuiThemeProvider>
        </Paper>
        <img className="background-image" src={backgroundImage} />
      </div></React.Fragment>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SignIn);
