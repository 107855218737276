import React from "react";
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme
} from "@material-ui/core/styles";
import backgroundImage from "../../assets/images/backgroundimage.png";
import solverseLogo from "../../assets/icons/solverseBlue.png";
import Button from "@material-ui/core/Button";
import { LOCALSTORAGEKEY_MAINMENU, MENU_PROJECT, MENU_USER, MENU_REPORT } from "../../utils/constants";
import { Auth } from "aws-amplify";
import  MenuContext  from '../../context/menuContext'

const projectMenu = [
  'SUSPEND','DELETE','BACK'
]
const usersMenu = [
  'SUSPEND','DELETE','BACK'
]
const reportsMenu = [
  'PROJECT LISTING SUMMARY','PROJECT LISTING BY USER','PAYMENT DETAILS BY USER'
]
const styles = theme => ({
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 2 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  cendiv: {
    display:'flex',
    height:'100vh',
    flexDirection:"column",
    justifyContent:'center'
  },
  but: {
    backgroundColor:'#18558d',
    width:'345px',
    height:'50px',
    marginBottom: 10,
    color: 'white',
    border: '1px solid #2A9FD8',
    '&:hover': {
      backgroundColor:'#18558d'
    }
  },
  paper: {
    backgroundColor: "white",
    boxShadow: "none",
    marginTop: theme.spacing.unit * 12,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  form: {
    width: "85%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  termsText: {
    fontSize: "12.7px",
    paddingTop: "3%",
    color: "#4d5356"
  },
  signupText: {
    fontSize: "14px",
    paddingTop: "3%",
    color: "#4d5356"
  },
  mainHead: {
    //fontFamily: 'Roboto-Medium',
    color: "rgba(76, 74, 74, 0.87)",
    fontSize: "26px",
    fontWeight: "bold",
    letterSpacing: "-0.34px",
    lineHeight: "29px"
  },
  submit: {
    marginTop: "10px",
    padding: "12px"
  },
  rememberMeRectangle: {
    width: "17px",
    height: "17px"
  },
  textField: {
    width: "300px",
    height: "42px",
    marginTop: "0px"
    //backgroundColor:"#fff"
  },
  boline: {
    /* margin-top: 4%; */
    height: 25,
    width: 1,
    backgroundColor: "black",
    marginRight: "2%"
  },
  boheading: {
    letterSpacing: "3px",
    fontWeight: 500,
    fontSize: "larger",
    color: "#666666"
  },
  top: {
    display: "flex",
    /* align-content: center; */
    alignItems: "center",
    marginTop: "2%",
    marginLeft: "4%"
  },
  imgclass: {
    marginRight: "2%"
  }
});
const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: "#00558D"
    },
    secondary: {
      main: "#838a8d"
    }
  }
});
class MainMenu extends React.Component {
  state = {
    selectedMenu: MENU_PROJECT
  }
  static contextType = MenuContext
  // menu = '';
  componentDidMount() {
    const menu = this.context
    console.log(menu) // { name: 'Tania', loggedIn: true }

    Auth.currentAuthenticatedUser()
    .then(user => {
      console.log('user.username')

    }).catch(err => {
      console.log("Error@@@@@@@@@@@@")
      this.props.history.push("/")
    })
  }

  componentWillMount() {
    try {
      Auth.currentAuthenticatedUser().then(user => {
        const token = user.signInUserSession.accessToken.jwtToken
        if(!token) {
          this.props.history.push("/")
        }
      })
    }
    catch(e) {
      this.props.history.push("/")
    }
  }
  render() {
    const { classes } = this.props;
    console.log('this.selectedMenu ' + this.state.selectedMenu)
    return (
      // <MenuProvider selectedMenu={this.state.selectedMenu}>
      <React.Fragment>
        <div className={classes.top}>
          <img className={classes.imgclass} src={solverseLogo} alt="" />
          <div className={classes.boline} />
          <div className={classes.boheading}>SUPER ADMIN</div>
        </div>
        <div className={classes.main}>
        <div className={classes.cendiv}>
          <Button className={classes.but} 
          onClick={() =>
           {
            const menu = this.context
            menu.selectedMenu = MENU_PROJECT
            localStorage.setItem(LOCALSTORAGEKEY_MAINMENU,MENU_PROJECT)
             {this.props.history.push("submenu", { menuItems: projectMenu })}
            }
            }
           >
            PROJECTS</Button>
          <Button className={classes.but}
           onClick={() =>{
            const menu = this.context
             menu.selectedMenu = MENU_USER
             localStorage.setItem(LOCALSTORAGEKEY_MAINMENU,MENU_USER)

             {this.props.history.push("submenu", { menuItems: usersMenu })}}
            }
           >                    
           USERS</Button>
          <Button className={classes.but}  
          
          onClick={() => {
            const menu = this.context
            menu.selectedMenu = MENU_REPORT
            localStorage.setItem(LOCALSTORAGEKEY_MAINMENU,MENU_REPORT)

            this.props.history.push("submenu", { menuItems: reportsMenu })}}
          >
          REPORTS
          </Button>
        </div>
          <img className="background-image" src={backgroundImage} />
        </div>
      </React.Fragment>
      // </MenuProvider>
    );
  }
}

export default withStyles(styles)(MainMenu);
