import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme
} from "@material-ui/core/styles";


const styles = theme => ({
 text: {
   padding: '4% 2% 0% 2%'
 },
 divStyle: {
  textAlign: 'center',
  paddingBottom: '2%'
  
},
button: {
  padding: '1.5% 1.8%',
  minWidth: '21%',
  margin: '1.5%'
}
});

 function Modal(props) {
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const theme1 = createMuiTheme({
    typography: {
      useNextVariants: true
    },
    palette: {
      primary: {
        main: "#00558D",
        dark: "#00558D",
        light: "#00558D"
      },
      secondary: {
        main: "#666666"
      }
    }
  });
  function handleClose() {
    setOpen(false);
    if(props.closeModal){
      props.closeModal()
    }
  }
  function projectView() {
    props.popup('value')
  }

  const { classes } = props;
  return (
    <div>
       <MuiThemeProvider theme={theme1}>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {/* <DialogTitle id="responsive-dialog-title">{"Select or Create a Project"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText className={classes.text}>
            {props.text}
          </DialogContentText>
        </DialogContent>
        
          {props.cancel ?
          <div className={classes.divStyle}>
          <Button onClick={() => props.cancel('value')} variant="contained" className={classes.button} color="primary">CANCEL
            {props.buttonText2}
          </Button>
          <Button onClick={() => props.popup('value')} variant="contained" className={classes.button} color="primary">
            {props.buttonText}
          </Button>
          </div>
          : 
          <div className={classes.divStyle}>
          <Button onClick={() => props.popup('value')} variant="contained" className={classes.button} color="primary">
            {props.buttonText}
          </Button>
          </div>
         }
          
      </Dialog>
      </MuiThemeProvider>
    </div>
  );
}
export default withStyles(styles)(Modal);
